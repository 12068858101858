import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Blog.css';

function Blog() {
  return (
    <section id="about" className="resume-container">

      
        <div className="article-block">
        <Link to="/when_to_use_aa_article">
        <div className="article-title">
          <h2>When Not to Use Async/Await in C#: A Practical Guide</h2>
        </div>

        <p className='date-label'>Date: 2024/Oct/07 </p>

        <div className="article-description">
          <p>While async/await is a powerful feature for improving the performance of I/O-bound tasks in C#, there are cases where its overuse can negatively impact your application's efficiency and maintainability. In this article, we dive into real-world scenarios where using async/await is not advisable, including CPU-bound tasks, short operations, and deep call stacks. Learn from practical examples and avoid common pitfalls to maintain performance and keep your codebase clean and easy to debug.</p>
        </div>
        <div className="article-link">
        </div>
        </Link>

      </div>

      <div className="article-block">
        <Link to="/aa_article">
        <div className="article-title">
          <h2>Mastering Asynchronous Programming in C#</h2>
        </div>

        <p className='date-label'>Date: 2024/Sept/30 </p>

        <div className="article-description">
          <p>Asynchronous programming is crucial for building responsive and scalable C# applications. This article breaks down how C#'s async/await pattern simplifies asynchronous tasks like file I/O and network calls, leading to better performance and maintainability. Explore how Task management in the .NET framework allows you to handle multiple operations concurrently, and learn best practices for ensuring smooth, non-blocking code execution. Whether you're new to async or looking to deepen your understanding, this guide covers everything from real-world use cases to essential async programming tips.</p>
        </div>
        <div className="article-link">
        </div>
        </Link>
      </div>


      <div className="article-block">
      <Link to="/oop_article">
        <div className="article-title">
          <h2>Object-Oriented Programming (OOP) in C# and JavaScript: A Comparative Guide</h2>
        </div>
        <p className='date-label'>Date: 2024/Sept/23 </p>
        <div className="article-description">
          <p>This article explores the key principles of Object-Oriented Programming (OOP) in C# and JavaScript, comparing their approaches to classes, inheritance, encapsulation, and polymorphism. It highlights the differences between C#'s class-based, statically typed system and JavaScript's prototype-based, dynamically typed flexibility. With practical examples and side-by-side code snippets, the guide helps developers understand how each language implements OOP, offering insights to choose the right language for specific project needs.</p>
        </div>
        <div className="article-link">
        </div>
        </Link>
        </div>



    </section>
  );
}

export default Blog;
